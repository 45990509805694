<template>
  <ProgressLoader
    v-if="breadcrumbLoading"
    mode="indeterminate"
    color="#5AC396"
    size="150px"
  />
  <div v-else>
    <AuBreadcrumb :pages="breadcrumb" />
    <div class="card">
      <div class="header">
        <div class="top-header">
          <div>
            <div class="title">
              {{ $t("router.pages.projectPortfolioTracker") }}
            </div>
            <div class="title">
              <span class="explain">
                {{ $t("auCapitals.capitalHistory.project") }}
                <span class="data">{{ currentCapital?.tokenBase?.nameEng }}
                </span>
              </span>
            </div>
          </div>

          <AuButton
            class="btn-add "
            bordered
            center
            type="primary"
            @click="createNewTracker"
          >
            <span class="bnt-text">
              {{ $t("projectPortfolioTracker.addTracker") }}
            </span>
          </AuButton>
        </div>

        <div />
      </div>
      <AuTable
        class="projects-table"
        :columns="columns"
        :items="items"
        :loading="dataLoading"
      />
      <AuPagination
        v-if="portfolioTotal > itemsPerPage"
        :total-items="portfolioTotal"
        @pageChanged="changePage"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProjectPortfolioTracker",

  components: {
    ProgressLoader
  },

  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 30,
      currentCapital: [],
      portfolioTrackers: [
        {
          id: "1",
          balance: "1",
          profit: "1",
          drawdown: "1",
          sharpCoef: "1"
        },
        {
          id: "2",
          balance: "2",
          profit: "2",
          drawdown: "2",
          sharpCoef: "2"
        }
      ],
      columns: [
        {
          name: `${this.$t("projectPortfolioTracker.balance")}`,
          align: "start"
        },
        {
          name: `${this.$t("projectPortfolioTracker.profit")}`,
          align: "center"
        },
        {
          name: `${this.$t("projectPortfolioTracker.drawdown")}`,
          align: "center"
        },
        {
          name: `${this.$t("projectPortfolioTracker.sharpCoef")}`,
          align: "center"
        }
      ],
      breadcrumbLoading: true
    };
  },
  computed: {
    ...mapGetters({
      ethAddresses: "ethAddresses",
      portfolioTotal: "capitals/portfolioTotal"
    }),

    breadcrumb() {
      const breadcrumb = [
        { key: 0, name: this.$t("router.pages.dashboard"), path: "/" },
        { key: 1, name: this.$t("projects.label"), path: "/my-projects" }
      ];
      if (this.projectName) {
        breadcrumb.push({
          key: breadcrumb?.length,
          name: this.projectName,
          path: `/project-card/${this.id}`
        });
      }
      return breadcrumb;
    },

    address() {
      return _.get(this.ethAddresses, "[0]", "");
    },

    projectName() {
      return this.currentCapital?.tokenBase?.nameEng || null;
    },

    items() {
      return _.map(this.portfolioTrackers, (item) => ({
        balance: item?.balance,
        profit: item?.profit,
        drawdown: item?.drawdown,
        sharpCoef: item?.sharpCoef
      }));
    }
  },
  async mounted() {
    if (this.$auth.user().type != "issuer") {
      this.$router.push({ name: "base" });
    }
    this.currentCapital = await this.getAuCapitals(this.id);
    await this.getItems();
    this.breadcrumbLoading = false;
  },
  methods: {
    ...mapActions({
      getAuCapitals: "capitals/getAuCapitals",
      getPortfolioTracker: "capitals/getPortfolioTracker"
    }),

    async createNewTracker() {
      if (!this.address?.verifiedAt || !this.address?.status) {
        this.showError(this.$t("toasts.error.projectNeedVerify"));
        return;
      }
      this.$router.push({ name: "edit portfolio", params: { id: this.id } });
    },

    async getItems() {
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        auCapitalId: this.id
        // sortBy: "value",
        // direction: "desc"
      };

      await this.getPortfolioTracker(params);
      const { data } = await this.getPortfolioTracker(params);
      this.portfolioTrackers = data;
    },

    async changePage(page) {
      this.page = page;
      await this.getItems();
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  width: 100%;
  padding: 20px 22px 22px 20px;
}

.header {
  margin-bottom: 20px;
  .title {
    font-size: 24px;
    color: var(--text-color);
    line-height: 0.9;
  }
  .explain {
    font-weight: 450;
    font-size: 12px;
    color: var(--color-60);
    .data {
      font-weight: 500;
      font-size: 14px;
      color: var(--color-60);
    }
  }
}
.projects-table {
  margin-bottom: 0;
}
.top-header {
  display: flex;
  justify-content: space-between;
}
</style>
