<template>
  <ProgressLoader
    v-if="breadcrumbLoading"
    mode="indeterminate"
    color="#5AC396"
    size="150px"
  />
  <div v-else>
    <AuBreadcrumb :pages="breadcrumb" />
    <div class="card">
      <div class="header">
        <div class="top-header">
          <div>
            <div class="title">
              {{ $t("router.pages.projectCreatePortfolioTracker") }}
            </div>
            <div class="title">
              <span class="explain">
                {{ $t("auCapitals.capitalHistory.project") }}
                <span class="data">{{ currentCapital?.tokenBase?.nameEng }}
                </span>
              </span>
            </div>
          </div>

          <AuButton
            class="btn-add"
            bordered
            center
            @click="goBack"
          >
            <span class="bnt-text">
              {{ $t("projectPortfolioTracker.cancel") }}
            </span>
          </AuButton>
        </div>

        <div />
      </div>
      <div class="i-block">
        <div class="i-line">
          <div class="is-label">
            {{ $t("projectPortfolioTracker.balanceLabel") }}
          </div>
          <div class="is-indent">
            <AuInput
              v-model="balance"
              required
              :placeholder="$t('projectPortfolioTracker.balancePlaceholder')"
              type="NullableNumber"
            />
          </div>
        </div>
        <div class="i-line">
          <div class="is-label">
            {{ $t("projectPortfolioTracker.profitLabel") }}
          </div>
          <div class="is-indent">
            <AuInput
              v-model="profit"
              required
              :placeholder="$t('projectPortfolioTracker.profitPlaceholder')"
              type="NullableNumber"
            />
          </div>
        </div>
      </div>
      <div class="i-block">
        <div class="i-line">
          <div class="is-label">
            {{ $t("projectPortfolioTracker.drawdownLabel") }}
          </div>
          <div class="is-indent">
            <AuInput
              v-model="drawdown"
              required
              :placeholder="$t('projectPortfolioTracker.drawdownPlaceholder')"
              type="NullableNumber"
            />
          </div>
        </div>
        <div class="i-line">
          <div class="is-label">
            {{ $t("projectPortfolioTracker.sharpCoefLabel") }}
          </div>
          <div class="is-indent">
            <AuInput
              v-model="sharpCoef"
              required
              :placeholder="$t('projectPortfolioTracker.sharpCoefPlaceholder')"
              type="NullableNumber"
            />
          </div>
        </div>
      </div>
      <AuButton
        :disabled="!isSaveBtn"
        width="100%"
        type="primary"
        center
        @click="savePortfolio"
      >
        {{ $t("projectPortfolioTracker.saveBtn") }}
      </AuButton>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import ProgressLoader from "@/loaders/progress-bar";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProjectPortfolioTracker",

  components: {
    ProgressLoader
  },

  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      currentCapital: [],
      breadcrumbLoading: true,
      balance: null,
      profit: null,
      drawdown: null,
      sharpCoef: null,
      tokensCountHasError: false
    };
  },
  computed: {
    ...mapGetters({
      ethAddresses: "ethAddresses",
      portfolioTotal: "capitals/portfolioTotal"
      // portfolioTracker: "capitals/portfolioTracker"
    }),

    isSaveBtn() {
      return this.balance && this.profit && this.drawdown && this.sharpCoef;
    },

    breadcrumb() {
      const breadcrumb = [
        { key: 0, name: this.$t("router.pages.dashboard"), path: "/" },
        { key: 1, name: this.$t("projects.label"), path: "/my-projects" }
      ];
      if (this.projectName) {
        breadcrumb.push({
          key: breadcrumb?.length,
          name: this.projectName,
          path: `/project-card/${this.id}`
        });
      }
      return breadcrumb;
    },

    address() {
      return _.get(this.ethAddresses, "[0]", "");
    },

    projectName() {
      return this.currentCapital?.tokenBase?.nameEng || null;
    }
  },
  async mounted() {
    if (this.$auth.user().type != "issuer") {
      this.$router.push({ name: "base" });
    }
    this.currentCapital = await this.getAuCapitals(this.id);
    this.breadcrumbLoading = false;
  },
  methods: {
    ...mapActions({
      getAuCapitals: "capitals/getAuCapitals",
      putPortfolioTracker: "capitals/putPortfolioTracker"
    }),

    async savePortfolio() {
      const params = {
        auCapital: `/api/au_capitals/${this.id}`,
        balance: this.balance,
        profit: this.profit,
        drawdown: this.drawdown,
        sharpCoef: this.sharpCoef,
        createdAt: new Date()
      };
      try {
        const response = await this.putPortfolioTracker(params);

        if (response?.code == 200) {
          this.showSuccess(this.$t("toasts.success.portfolioSend"));
          this.$router.push({ name: "portfolio tracker", params: { id: this.id } });
        }
        else {
          this.showError(response?.message || this.$t("toasts.error.portfolioSend"));
        }
      }
      catch (error) {
        this.showError(this.$t("toasts.error.portfolioSend"));
      }
    },

    goBack() {
      this.$router.push({ name: "portfolio tracker", params: { id: this.id } });
    },

    showSuccess(message) {
      this.$toast.success(message);
    },

    showError(message) {
      this.$toast.error(message);
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  width: 100%;
  padding: 20px 22px 22px 20px;
}

.header {
  margin-bottom: 20px;
  .title {
    font-size: 24px;
    color: var(--text-color);
    line-height: 0.9;
  }
  .explain {
    font-weight: 450;
    font-size: 12px;
    color: var(--color-60);
    .data {
      font-weight: 500;
      font-size: 14px;
      color: var(--color-60);
    }
  }
}
.i-block {
  display: flex;
  gap: 0 8px;
}
.i-line {
  width: 100%;
}
.top-header {
  display: flex;
  justify-content: space-between;
}
</style>
